import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';
import DocumentsContent from '../components/DocumentsContent';
import Layout from '../components/Layout';

const DocumentsPage = ({ page }) => {
  if (!page && typeof window !== 'undefined') {
    navigate('/dokumenty/strona/0');
    return null;
  }
  return (
    <Layout>
      <DocumentsContent
        page={Number.isNaN(Number(page)) || Number(page) < 0 ? 0 : Number(page)}
      />
    </Layout>
  );
};

DocumentsPage.propTypes = {
  page: PropTypes.string.isRequired,
};

DocumentsPage.defaultProps = {};

export default DocumentsPage;
